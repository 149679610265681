import {AxiosInstance} from "axios";

export function setLoadingOverlayDisplay(show: boolean): void {
    const ele = document.getElementById("loading-overlay");
    if (ele) {
        ele.style.display = show ? "flex" : "none"
    }
}


export function setLongLoadingOverlayDisplay(show: boolean): void {
    if (show) {
        const ele = document.getElementById("loading-overlay");
        if (ele) {
            ele.style.display = "flex";
            ele.id = "long-loading-overlay";
        }
    }
    else {
        const ele = document.getElementById("long-loading-overlay");
        if (ele) {
            ele.style.display = "none";
            ele.id = "loading-overlay";
        }
    }
}

export function registerAxiosLoadingHook(axiosInstance: AxiosInstance): void {
    axiosInstance.interceptors.request.use((config) => {
        setLoadingOverlayDisplay(true)
        return config;
    }, (error) => {
        setLoadingOverlayDisplay(true)
        return Promise.reject(error);
    });

    axiosInstance.interceptors.response.use((response) => {
        setLoadingOverlayDisplay(false)
        return response;
    }, (error) => {
        setLoadingOverlayDisplay(false)
        return Promise.reject(error);
    });
}