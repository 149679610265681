import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/global.scss';
import {FINGERPRINT, SPM} from "@/utils/localStorage";
import {getQueryVariable} from "@/utils/url";
import {v4 as uuidv4} from 'uuid';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// Initialize an agent at application startup.
;(async () => {
  const spm = getQueryVariable(SPM);
  if (spm != null && (!isNaN(parseInt(spm)))) {
    const spmInt = parseInt(spm)
    localStorage.setItem(SPM, spmInt.toString())
  } else if (localStorage[SPM] == null) {
    const spm = Math.round(Math.random() * 100000000)
    localStorage.setItem(SPM, spm.toString())
  }
  if (!localStorage[FINGERPRINT]) {
    localStorage.setItem(FINGERPRINT, uuidv4())
  }
})()


