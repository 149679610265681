import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
// import Home from '../views/Home.vue'
import Login from "@/views/Login.vue";
import OnlyWechatAllowed from "@/views/OnlyWechatAllowed.vue";
import ItemPage from "@/views/preorder/ItemPage.vue";
import weixinMpService from "@/services/WeixinMpService"
import {Api} from "@/backendClient/Api";
import LibraryBook from "@/views/order/LibraryBook.vue";
import AppDownload from "@/views/order/AppDownload.vue";
import {CUR_ROUTE_PATH} from "@/utils/localStorage";

Vue.use(VueRouter)

const client = new Api({baseURL: `${process.env.VUE_APP_BACKEND_URL}/api`})

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: () => {
      return "/page/libraryBook"
    }
  },
  {
    path: '/go/wxCallback',
    beforeEnter: (to, from, next) => {
      console.log(`wxCallback: ${to.fullPath}`)
      console.log(JSON.stringify(to.query))
      if (to.query.code && to.query.r) {
        const code = to.query.code.toString()
        console.log(`Got code: ${to.query.code}}`)
        client.weixin.mpCodeAuthorize({
          code: code,
          scope: "snsapi_base"
        }).then(ret => {
          console.log(`Got user's openId: ${ret.data.openId}`)
          weixinMpService.openId = ret.data.openId || null
          console.log(`Navigating to ${to.query.r}`)
          next(to.query.r.toString())
        })
      }
    }
  },
  {
    path: '/go/landing',
    redirect: to => {
      console.log("landing page", to)
      const queryName = String(to.query['name']);
      // 判断是预售还是直接发售
      if (queryName != null &&
          (queryName.startsWith("chinaMachine_999"))
      ) {
        return to.path.replace("/go/landing", "/campaign202104/item")
      }
      return to.path.replace("/go/landing", "/page/libraryBook")
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/page/onlyWechatAllowed',
    component: OnlyWechatAllowed
  },
  {
    path: '/page/libraryBook',
    component: LibraryBook,
    beforeEnter: (to, from, next) => {
      checkWxOpenId(to.fullPath)
      next()
    }
  },
  {
    path: '/page/app',
    component: AppDownload
  },
  {
    path: '/campaign202104/item',
    component: ItemPage,
    beforeEnter: (to, from, next) => {
      // if (process.env.NODE_ENV != "development") {
      //   console.log("is weixin", is_weixin())
      //   if (!is_weixin()) {
      //     next("/page/onlyWechatAllowed")
      //   }
      // }

      checkWxOpenId(to.fullPath)
      next()
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MySpace.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  localStorage.setItem(CUR_ROUTE_PATH, to.path);
  // Hack of onlyWechatAllowed page...
  if (to.path == "/page/onlyWechatAllowed") {
    document.body.style.backgroundColor = "#e5e5e5";
  } else {
    document.body.style.backgroundColor = "initial";
  }

  weixinMpService.doConfig().finally(() => {
    next()
  })
})

function checkWxOpenId(targetRoute: string) {
  if (weixinMpService.openId == undefined) {
    if (targetRoute.indexOf('campaign202104') != -1 || navigator.userAgent.toLowerCase().indexOf('micromessenger') != -1) {
      const r1 = `${targetRoute}`
      const r2 = `${window.location.origin}/go/wxCallback?r=${encodeURIComponent(r1)}&code=__CODE__`
      const r3 = `https://api.qianmoai.com/api/weixin/v1/mp/callback?r=${encodeURIComponent(r2)}`
      const scope = "snsapi_base"
      const appid = weixinMpService.appId
      const rx = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(r3)}&response_type=code&scope=${scope}&state=unused#wechat_redirect`

      window.location.href = rx
    } else {
      localStorage.setItem("wx_env", "false");
    }
  } else {
    localStorage.setItem("wx_env", "true")
  }
}


export default router
