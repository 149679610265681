













































import {Component, Vue} from 'vue-property-decorator';
import {Api} from "@/backendClient/Api";
import authService from "@/services/AuthService";
import {InitWithNvcCallback} from "@/services/aliyunCaptcha";
import {registerAxiosLoadingHook} from "@/utils/loadingOverlay/LoadingOverlay";
import {FINISH_LOGIN_PREORDER, FINISH_LOGIN, logUserAction} from "@/utils/logUserAction";

// eslint-disable-next-line
declare const window: any;

@Component
export default class LoginWidget extends Vue {
  private phoneNumber = ""
  private step = 1
  private resendCounter = 0
  private client = new Api({baseURL: `${process.env.VUE_APP_BACKEND_URL}/api`})
  private code = ""

  private captcha = "";

  public init(): void {
    this.phoneNumber = ""
    this.step = 1
    this.resendCounter = 0
    this.client = new Api({baseURL: `${process.env.VUE_APP_BACKEND_URL}/api`})
    registerAxiosLoadingHook(this.client['instance'])
    this.code = ""

    this.captcha = "";
  }

  public mounted(): void {
    this.init()

    InitWithNvcCallback(".captcha", data => {
      console.log("Got second time verify data")
      this.captcha = data
      // call request again
      this.requestToSendCode()
    })

    // Insert aliyun scripts
    // let s1 = document.createElement('script')
    // s1.setAttribute('src', '//g.alicdn.com/mtb/lib-flexible/0.3.4/??flexible_css.js,flexible.js')
    // document.head.appendChild(s1)
    let s2 = document.createElement('script')
    s2.setAttribute('src', '//g.alicdn.com/sd/nvc/1.1.112/guide.js')
    document.head.appendChild(s2)
  }

  private resendCounterTimerId = 0

  public requestToSendCode(): void {
    console.log("phoneNumber:", this.phoneNumber)
    if (this.phoneNumber.length != 11) {
      return
    }
    // 倒计时限制
    // if (this.resendCounter > 0) {
    //   return
    // }
    this.resendCounter = 60
    this.resendCounterTimerId = setInterval(() => {
      this.resendCounter -= 1
      if (this.resendCounter == 0) {
        clearInterval(this.resendCounterTimerId)
      }
    }, 1000)

    if (this.captcha == "") {
      this.captcha = window.getNVCVal()
      console.log("NVCVal: ", this.captcha)
    }

    this.client.auth.signInSendSmsCode({
      phone_number: this.phoneNumber,
      country_code: 86,
      captcha: this.captcha,
      purpose: "SIGNIN"
    }).then(r => {
      console.log(r.config.url)
      console.log(r.status)
      console.log(r.data)
      let bizCode = r.data.bizCode
      if (bizCode == 400) {
        //唤醒滑动验证
        window.getNC().then(function () {
          window.NoCaptcha.upLang('cn', {
            'LOADING': "加载中...",//加载
            'SLIDER_LABEL': "请向右滑动验证",//等待滑动
            'CHECK_Y': "验证通过",//通过
            'ERROR_TITLE': "非常抱歉，这出错了...",//拦截
            'CHECK_N': "验证未通过", //准备唤醒二次验证
            'OVERLAY_INFORM': "经检测你当前操作环境存在风险，请输入验证码",//二次验证
            'TIPS_TITLE': "验证码错误，请重新输入"//验证码输错时的提示
          });
          window._nvc_nc.reset();
        })
      } else if (bizCode == 600) {
        //唤醒刮刮卡
        window.getSC()
      } else if (bizCode == 700) {
        //唤醒问答验证码
        window.getLC()
      } else if (bizCode == 100 || bizCode == 200 || bizCode == 0) {
        //注册成功
        window.nvcReset()
        this.onSendSmsCodeSuccess()
      } else if (bizCode == 800 || bizCode == 900) {
        //直接拦截
        window.nvcReset()
        this.onSendSmsCodeFailed()
      }
    }).catch(err => {
      console.log(err)
      this.onSendSmsCodeFailed()
    })
  }

  private onSendSmsCodeSuccess() {
    console.log("验证码短信已发送，请查收!")
    if (this.step == 1) {
      // move to next step
      this.step = 2;
      setInterval(() => {
        if (this.$refs.codeInput instanceof Element) {
          (this.$refs.codeInput as HTMLElement)?.focus()
        }
      }, 200)
    }
  }

  private onSendSmsCodeFailed(): void {
    alert("短信发送失败，请刷新页面后重试！")
  }

  verifyCode(): void {
    if (this.code.length != 4) {
      return
    }

    this.client.auth.signInByPhone({
      method_name: "sms_code",
      phone_number: this.phoneNumber,
      country_code: 86,
      code: this.code,
    }).then(r => {
      this.code = ""
      console.log(r.config.url)
      console.log(r.status)
      console.log(r.data)
      authService.setJwt(r.data.token)
      this.closeLoginWidget()
      this.$emit("loginSuccessful")
      logUserAction(this.client, FINISH_LOGIN_PREORDER, {})
      logUserAction(this.client, FINISH_LOGIN, {})
    }).catch(() => {
      this.code = ""
      alert("验证码输入有误，请重新输入")
      setInterval(() => {
        if (this.$refs.codeInput instanceof Element) {
          (this.$refs.codeInput as HTMLElement)?.focus()
        }
      }, 200)
    })
  }

  backToStep1(): void {
    this.init()
  }

  closeLoginWidget(): void {
    this.$emit("loginClosed")
  }
}
