














































import {Component, Vue} from "vue-property-decorator";
import ItemDetail from "@/views/preorder/ItemDetail.vue"
import My2 from "@/views/My2.vue";
import LoginWidget from "@/components/LoginWidget.vue"
import authService from "@/services/AuthService"
import {Api} from "@/backendClient/Api";
import weixinMpService from "@/services/WeixinMpService"
import {registerAxiosLoadingHook} from "@/utils/loadingOverlay/LoadingOverlay";
import {
  CLICK_BOOK_NOW_BUTTON_PREORDER,
  CLICK_PAY_BUTTON_PREORDER,
  FINISH_PAY_PREORDER,
  logUserAction,
  VIEW_PAGE_PREORDER
} from "@/utils/logUserAction";
import {getJWT, getSpm} from "@/utils/localStorage";

// eslint-disable-next-line
declare const pingpp: any;

class ItemInfo {
  constructor(readonly name: string, readonly price: string, readonly originalPrice: string) {
  }
}

@Component({
  components: {
    ItemDetail,
    My2,
    LoginWidget
  },
})
export default class ItemPage extends Vue {
  private showLogin = false;
  private spm = getSpm() % 3
  private itemId = ""
  private client ?: Api<never>

  private status = "NOT_BOOKED"
  private isLogin = false

  // UI上显示的价格原价
  private price = ""
  private originalPrice = ""

  private itemMap = new Map([
    ["chinaMachine_001", new ItemInfo("逻辑精点", "9.9", "30.0")],
    ["chinaMachine_002", new ItemInfo("数学高分指南", "9.9", "30.0")],
    ["chinaMachine_003", new ItemInfo("写作分册精点", "9.9", "29.8")],
    ["chinaMachine_004", new ItemInfo("数学分册", "9.9", "29.8")],
    ["chinaMachine_999", new ItemInfo("测试商品", "0.01", "99.0")],
  ]);


  init(): void {
    let queryName = this.$route.query.name;
    if (typeof queryName != "string") {
      queryName = "chinaMachine_001_cover2"
    }

    const m = queryName.match(/^(chinaMachine_\d{3}).*/)
    this.itemId = m != null ? m[1] : "chinaMachine_001"
    localStorage.setItem("sku",this.itemId)
    console.log("Displaying item", this.itemId)
    const item = this.itemMap.get(this.itemId)
    document.title = item?.name || ""
    this.price = item?.price || ""
    this.originalPrice = item?.originalPrice || ""

    if (authService.getJwt() != null) {
      this.isLogin = true
      this.client = new Api({
        baseURL: `${process.env.VUE_APP_BACKEND_URL}/api`,
        headers: {Authorization: "Bearer " + authService.getJwt()}
      })
      registerAxiosLoadingHook(this.client['instance'])
      logUserAction(this.client, VIEW_PAGE_PREORDER, {})
      this.client.campaign.campaign202104GetStatus(this.itemId, {}).then(x => {
        this.status = x.data.status
      })
    } else {
      this.client = new Api({baseURL: `${process.env.VUE_APP_BACKEND_URL}/api`})
      logUserAction(this.client, "campaign202104-view-page", {})
      this.isLogin = false
    }
  }

  mounted(): void {
    this.init()
  }


  async bookNow() : Promise<void> {
    if (getJWT() == null) {
      this.showLogin = true
      logUserAction(this.client, CLICK_BOOK_NOW_BUTTON_PREORDER, {})
      return
    }
    let ret = await this.client?.campaign.campaign202104Post(this.itemId, {action: 'BOOK'})
    if (ret) {
      this.status = ret.data.status
    }
  }

  async orderNow() : Promise<void> {
    logUserAction(this.client, CLICK_PAY_BUTTON_PREORDER, {})
    let openId = weixinMpService.openId
    if (openId == null) {
      alert("无法获得微信用户信息，请刷新后重试！")
      return
    }
    let ret = await this.client?.campaign.campaign202104Post(this.itemId, {action: "PLACE_ORDER", openId: openId})
    console.log("ret is", ret)
    let charge = ret?.data.charge;
    if (charge == null) {
      console.warn("charge is null!")
      return
    }
    console.log("charge is", charge)
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this

    pingpp.createPayment(charge, function (result, err) {
      console.log(JSON.stringify(charge));
      console.log("result:" + result);
      console.log("err.msg:" + err.msg);
      console.log("err.extra:" + err.extra);
      if (result == "success") {
        // 只有微信JSAPI (wx_pub)、微信小程序（wx_lite）、QQ 公众号 (qpay_pub)、支付宝小程序（alipay_lite）支付成功的结果会在这里返回，其他的支付结果都会跳转到 extra 中对应的 URL
        alert("支付成功！")
        // 直接把状态改掉。如果后端还没更新，刷新页面后还会再弹支付。但延迟在5秒左右
        self.status = "ORDERED_PAID"
        logUserAction(self.client, FINISH_PAY_PREORDER, {})
      } else if (result == "fail") {
        // Ping++ 对象 object 不正确或者微信JSAPI/微信小程序/QQ公众号支付失败时会在此处返回
        alert("支付失败，请刷新后重试")
      } else if (result == "cancel") {
        // 微信JSAPI、微信小程序、QQ 公众号、支付宝小程序支付取消支付
      }
    });

  }

  onLoginClosed(): void {
    this.init()
    this.showLogin = false
  }

  logout(): void {
    authService.clearJwt()
    this.init()
  }

}

