







import {Component, Vue} from 'vue-property-decorator';

@Component({
})
export default class My2 extends Vue {
  public mounted(): void {
    console.log("mounted of my2")
  }

}

