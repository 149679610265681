class AuthService {
  private jwt: string | null

  constructor() {
    this.jwt = localStorage.getItem("jwt")
  }

  public getJwt(): string | null {
    return this.jwt
  }

  public clearJwt() {
    this.jwt = null
    localStorage.removeItem("jwt")
  }

  public setJwt(jwt: string) {
    this.jwt = jwt
    localStorage.setItem("jwt", jwt)
  }
}

export default new AuthService()
