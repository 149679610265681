


















































































































































































































































import {Component, Vue} from "vue-property-decorator";
import authService from "@/services/AuthService";
import {Api, CreateOrderResponse, GetLibraryBookInfo, LibraryBookLeaseResponse} from "@/backendClient/Api";
import {registerAxiosLoadingHook, setLongLoadingOverlayDisplay} from "@/utils/loadingOverlay/LoadingOverlay";
import {getFingerprint, getJWT, getSpm} from "@/utils/localStorage";
import LoginWidget from "@/components/LoginWidget.vue"
import weixinMpService from "@/services/WeixinMpService";
import {
  CLICK_BOOK_NOW_BUTTON,
  CLICK_BORROW_BUTTON,
  CLICK_PAY_BUTTON,
  FINISH_PAY,
  logUserAction,
  VIEW_PAGE
} from "@/utils/logUserAction";

declare const pingpp: any;

class ItemInfo {
  constructor(readonly name: string, readonly price: string, readonly originalPrice: string) {
  }
}


@Component({
  components: {
    LoginWidget
  },
})
export default class LibraryBook extends Vue {
  private showLogin = false;

  private spm = getSpm() % 3
  private itemId = ""
  private client ?: Api<never>

  private status = "NOT_BOOKED"
  private isLogin = false
  // UI上显示的价格原价
  private price = ""
  private originalPrice = ""
  private loadData = false
  private showDetails = true
  private showTableOfContent = false
  private couponDiscount = 0
  private couponID = -1
  private libraryBookId = -1;
  private itemMap = new Map([
    ["chinaMachine_001", new ItemInfo("逻辑精点", "9.9", "30.0")],
    ["chinaMachine_002", new ItemInfo("数学高分指南", "9.9", "30.0")],
    ["chinaMachine_003", new ItemInfo("写作分册精点", "6.6", "20.0")],
    ["chinaMachine_004", new ItemInfo("数学分册", "6.6", "20.0")],
    ["chinaMachine_999", new ItemInfo("测试商品", "0.01", "99.0")],
  ]);

  private libraryBook: GetLibraryBookInfo = {}
  private skuPrice: LibraryBookLeaseResponse = {}
  private order: CreateOrderResponse = {}
  private wx_env = localStorage.getItem("wx_env") === "true";
  private payChannel = this.wx_env ? "wx_pub" :"alipay_wap"


  mounted(): void {
    this.init()
  }

  gotoApp(): void {
    window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=ai.qianmo.huajiapp"
  }

  isMobile(): string {
    const Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
    let isMobile = false;
    const userAgentInfo = navigator.userAgent;
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        isMobile = true;
        break;
      }
    }

    let u = navigator.userAgent;
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
    let isWx = this.isWeixin()
    console.log('是否是Android：', isAndroid); //true,false
    console.log('是否是iOS：', isIOS)
    console.log('是否是Wx：', isWx)

    return "是否移动端-" + isMobile;
  }

  isWeixin(): boolean {
    let ua = navigator.userAgent;
    console.log(ua)
    return ua.indexOf("MicroMessenger") >= 0;
  }

  selectPayment(channel): void {
    let element: HTMLElement = document.getElementsByClassName(channel)[0] as HTMLElement;
    element.click();
    this.payChannel = channel;
  }

  selectCoupons(): void {
    this.couponDiscount = -this.couponDiscount;
  }

  showCoupons(): void {
    let ret = this.client?.coupon.getMyCouponList({library_book_id: this.libraryBookId}).then((ret) => {
      let coupons = ret.data.coupons
      if (coupons == undefined) {
        console.warn("coupons is null!")
        return
      }
      for (let i = 0; i < coupons.length; i++) {
        let x = coupons[i]
        if (x.is_consumed == false && x.library_book_id == this.libraryBookId) {
          let time = new Date(x.end_date!)
          if (time.getTime() > new Date().getTime()) {
            console.log("valid coupon is", x)
            this.couponDiscount = x.discount_value! / 100
            this.couponID = x.coupon_id!
          }
        }
      }
      console.log(this.couponDiscount)
    });
  }

  async showPrice(): Promise<void> {
    logUserAction(this.client, CLICK_BORROW_BUTTON, {})
    if (getJWT() == null) {
      this.showLogin = true
      return
    }
    let libraryBookSku = await this.client?.books.getLibraryBookSku(this.libraryBookId);
    console.log(libraryBookSku)
    if (libraryBookSku != undefined) {
      this.skuPrice = libraryBookSku.data
      this.status = "SHOW_PRICE"
    }
    await this.bookNow(60)
  }

  async bookNow(quantity: number): Promise<void> {
    logUserAction(this.client, CLICK_BOOK_NOW_BUTTON, {})
    if (getJWT() == null) {
      this.showLogin = true
      return
    }
    let resp = await this.client?.books.makeLibraryBookLeaseWithAiPlan(this.libraryBookId, {quantity: quantity})
    if (resp != undefined) {
      this.order = resp.data
      this.showCoupons()
      this.status = "SHOW_PAYMENT"
    }

  }

  async payNow(): Promise<void> {
    let url = window.location.href.replace("http://localhost:3000", "https://m.staging.qianmo8.com")
    logUserAction(this.client, CLICK_PAY_BUTTON, {})
    let ret = await this.client?.payment.createTransactionOrder(this.order.order_id!, {
      transaction_gateway: "PING_PP",
      pay_channel: this.payChannel,
      open_id: weixinMpService.openId!,
      coupon_id: this.couponDiscount > 0 ? this.couponID : undefined,
      success_callback_url: url
    })

    console.log(ret)
    if (ret!.data == null) {
      this.init()
    }
    let charge = JSON.parse(ret?.data["pingPPChargeString"])
    console.log(charge)
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let that = this
    pingpp.createPayment(charge, function (result, err) {
      console.log(JSON.stringify(charge));
      console.log("result:" + result);
      console.log("err.msg:" + err.msg);
      console.log("err.extra:" + err.extra);
      if (result == "success") {
        // 只有微信JSAPI (wx_pub)、微信小程序（wx_lite）、QQ 公众号 (qpay_pub)、支付宝小程序（alipay_lite）支付成功的结果会在这里返回，其他的支付结果都会跳转到 extra 中对应的 URL
        alert("支付成功！")
        // 直接把状态改掉。如果后端还没更新，刷新页面后还会再弹支付。但延迟在5秒左右
        that.status = "ORDERED"
        logUserAction(that.client, FINISH_PAY, {})
      } else if (result == "fail") {
        // Ping++ 对象 object 不正确或者微信JSAPI/微信小程序/QQ公众号支付失败时会在此处返回
        alert("支付失败，请刷新后重试")
      } else if (result == "cancel") {
        // 微信JSAPI、微信小程序、QQ 公众号、支付宝小程序支付取消支付
      }
    });
  }

  async init(): Promise<void> {
    let queryName = this.$route.query.name;
    if (typeof queryName != "string") {
      queryName = "chinaMachine_001_cover2"
    }
    const m = queryName.match(/^(chinaMachine_\d{3}).*/)
    this.itemId = m != null ? m[1] : "chinaMachine_001"
    console.log("Displaying item", this.itemId)
    const item = this.itemMap.get(this.itemId)
    this.price = item?.price || ""
    this.originalPrice = item?.originalPrice || ""

    let queryId = this.$route.query.bookid
    if (typeof queryId == "string") {
      const n = queryId.match(/^\d+$/)
      this.libraryBookId = n != null ? parseInt(n[0]) : this.libraryBookId
    }

    if (authService.getJwt() != null) {
      this.isLogin = true
      this.client = new Api({
        baseURL: `${process.env.VUE_APP_BACKEND_URL}/api`,
        headers: {Authorization: "Bearer " + authService.getJwt()}
      })
      await this.getApp()
      registerAxiosLoadingHook(this.client['instance'])
      let resp = await this.client.books.getLibraryBookSku(this.libraryBookId);
      if (resp.data.lease == undefined) {
        console.log(1);
      } else {
        this.status = "ORDERED";
      }
      console.log(this.status)

    } else {
      this.client = new Api({baseURL: `${process.env.VUE_APP_BACKEND_URL}/api`})
      await this.getApp()
    }
    this.showBrief();
    localStorage.setItem("sku", "libraryBookId_" + this.libraryBookId)
    logUserAction(this.client, VIEW_PAGE, {})
    let libraryInfo = this.client.books.getLibraryInfo(this.libraryBookId);
    console.log("libraryInfo", libraryInfo)
    libraryInfo.then(resp => {
      this.libraryBook = resp.data
      document.title = this.libraryBook.qm_title!
      this.loadData = true
    })
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let that = this;
    let isPaid = this.$route.query.from === "payment_done";
    if (isPaid) {
      let count = 0;
      setLongLoadingOverlayDisplay(true)
      let retryTimer = setInterval(async function () {
        count++;
        if (count > 10) {
          clearInterval(retryTimer)
          alert("无法获取购买信息，请刷新重试\n如有疑问，请联系工作人员(QQ：3566564241)")
          that.$route.query.from = "";
          setLongLoadingOverlayDisplay(false)
        }
        let resp = await that.client?.books.getLibraryBookSku(that.libraryBookId);
        if (resp?.data.lease != undefined) {
          that.status = "ORDERED";
          clearInterval(retryTimer)
          setLongLoadingOverlayDisplay(false)
        }
      }, 1000);
    }
  }


  async getApp(): Promise<void> {
    let resp = await this.client?.app.getAppInfo();
    let appInfojson = JSON.parse(resp!.data.china_machine_table!)
    if (this.libraryBookId == -1) {
      this.libraryBookId = appInfojson[this.itemId.substring(this.itemId.length - 3)]
    }
    console.log("libraryBookId", this.libraryBookId)
  }

  onLoginClosed(): void {
    this.init()
    this.showLogin = false
    if (getJWT()){
      this.showPrice()
    }
  }

  cancelBook(): void {
    this.status = "NOT_BOOKED"
  }

  showBrief(): void {
    if ([69, 272, 231].includes(this.libraryBookId)){
      this.showDetails = false;
    }
  }

  logout(): void {
    authService.clearJwt()
    window.location.href = window.location.href.replace(/[?&]from=payment_done/,"");
  }
}

