/* eslint-disable */

/**
 * 初始化阿里云验证码，然后请自行调用后端业务接口
 * @param renderTo like "#captcha"
 * @param secondTimeVerifyCallback function (data) {
      console.log(`nvcCallback: ${data}`)
      // data为`getNVCVal`()的值，此函数为二次验证滑动或者刮刮卡通过后的回调函数
      // data跟业务请求一起上传，由后端请求AnalyzeNvc接口，接口会返回100或者900
    }
 */
export const InitWithNvcCallback = (renderTo, secondTimeVerifyCallback) => {
  window.NVC_Opt = {
    //无痕配置 && 滑动验证、刮刮卡、问答验证码通用配置
    appkey: 'FFFF0N00000000009DA8',
    scene: 'nvc_login_h5',
    isH5: true,
    popUp: false,
    renderTo: renderTo, // Ex: '#captcha'
    nvcCallback: secondTimeVerifyCallback,
    trans: {"key1": "code0", "nvcCode": 400},
    language: "cn",
    //滑动验证长度配置
    customWidth: 300,
    //刮刮卡配置项
    width: 300,
    height: 100,
    elements: [
      '//img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png',
      '//img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png'
    ],
    bg_back_prepared: '//img.alicdn.com/tps/TB1skE5SFXXXXb3XXXXXXXXXXXX-100-80.png',
    bg_front: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABQCAMAAADY1yDdAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAADUExURefk5w+ruswAAAAfSURBVFjD7cExAQAAAMKg9U9tCU+gAAAAAAAAAIC3AR+QAAFPlUGoAAAAAElFTkSuQmCC',
    obj_ok: '//img.alicdn.com/tfs/TB1rmyTltfJ8KJjy0FeXXXKEXXa-50-74.png',
    bg_back_pass: '//img.alicdn.com/tfs/TB1KDxCSVXXXXasXFXXXXXXXXXX-100-80.png',
    obj_error: '//img.alicdn.com/tfs/TB1q9yTltfJ8KJjy0FeXXXKEXXa-50-74.png',
    bg_back_fail: '//img.alicdn.com/tfs/TB1w2oOSFXXXXb4XpXXXXXXXXXX-100-80.png',
    upLang: {
      "cn": {
        _ggk_guide: "请在屏幕上滑动，刮出两面盾牌",
        _ggk_success: "恭喜您成功刮出盾牌<br/>继续下一步操作吧",
        _ggk_loading: "加载中",
        _ggk_fail: ['呀，盾牌不见了<br/>请', "javascript:NoCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题'],
        _ggk_action_timeout: ['我等得太久啦<br/>请', "javascript:NoCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题'],
        _ggk_net_err: ['网络实在不给力<br/>请', "javascript:NoCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题'],
        _ggk_too_fast: ['您刮得太快啦<br/>请', "javascript:NoCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题']
      }
    }
  }
}


// //@typescript-eslint/explicit-module-boundary-types
// function yourRegisterRequest(url, params) {
//   var callbackName = ('jsonp_' + Math.random()).replace('.', '')
//   params += '&callback=' + callbackName
//   var o_scripts = document.getElementsByTagName("script")[0]
//   var o_s = document.createElement('script')
//   o_scripts.parentNode.insertBefore(o_s, o_scripts);
//   //您注册请求的业务回调
//   window[callbackName] = function (json) {
//     if (json.result.code == 400) {
//       //唤醒滑动验证
//       getNC().then(function () {
//         NoCaptcha.upLang('cn', {
//           'LOADING': "加载中...",//加载
//           'SLIDER_LABEL': "请向右滑动验证",//等待滑动
//           'CHECK_Y': "验证通过",//通过
//           'ERROR_TITLE': "非常抱歉，这出错了...",//拦截
//           'CHECK_N': "验证未通过", //准备唤醒二次验证
//           'OVERLAY_INFORM': "经检测你当前操作环境存在风险，请输入验证码",//二次验证
//           'TIPS_TITLE': "验证码错误，请重新输入"//验证码输错时的提示
//         });
//         _nvc_nc.reset();
//       })
//     } else if (json.result.code == 600) {
//       //唤醒刮刮卡
//       getSC().then(function () {
//       })
//     } else if (json.result.code == 700) {
//       //唤醒问答验证码
//       getLC()
//     } else if (json.result.code == 100 || json.result.code == 200) {
//       //注册成功
//       nvcReset()
//       alert("register success!")
//     } else if (json.result.code == 800 || json.result.code == 900) {
//       //直接拦截
//       nvcReset()
//       alert("register failed!")
//     }
//   }
//   o_s.src = url + '?' + params
// }

// export const invoke = () => {
//   init();
//   // 以下getNVCVal()的值，跟随业务请求一起上传，由后端请求AnalyzeNvc接口，返回200，400，600或者800
//   var params = 'a=' + getNVCVal()
//   yourRegisterRequest('http://cf.aliyun.com/nvc/nvcAnalyze.jsonp', params)
// }
//
