/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {getCurRoutePath, getFingerprint} from "@/utils/localStorage";
import { parse } from "lossless-json"

/**
 * 登录成功的响应体
 * @example {"token":"eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJlMTM3Y2E0OC0xYmFhLTQ3MjctODgxNC0xMmY1Y2NkYzM4ZDMiLCJpc3MiOiJodHRwOi8vZXhhbXBsZS5vcmciLCJhdWQiOiJodHRwOi8vZXhhbXBsZS5vcmciLCJzdWIiOiJhZG1pbiIsImlhdCI6MTYxNTQ3MzQ3NSwiZXhwIjoxNjE1NDc3MDc1LCJhdXRob3JpdGllcyI6WyJBRE1JTiIsIlVTRVIiXSwicmVmcmVzaENvdW50IjowLCJyZWZyZXNoTGltaXQiOjF9.GfF6EBzaaDRPFno_ij40ic9kmYTcJEYqJO7CCij3tiI","expire_date":"2020-03-01T00:00:00Z"}
 */
export interface AuthTokenResponse {
  token: string;

  /** @format date-time */
  expire_date: string;
}

/**
 * 通用的错误返回
 * @example {"domain":"auth","errorCode":40023,"message":"XX is invalid","params":{"xx":"yy"}}
 */
export interface ErrorResponse {
  /**
   * 业务上会将所有错误码定义好。可以用它作为翻译
   * @format int32
   */
  errorCode: number;

  /** 往往是内部错误信息或者英文提示，不建议直接显示给用户 */
  message: string;

  /** 一个string to string map，用于显示错误的额外信息，本字段一般情况下没有内容 */
  params: object;

  /** 指明该错误的所属领域。不同domain下error code可以重复，见各domain的error code定义 */
  domain: string;
}

/**
 * 登录请求
 * @example {"method_name":"sms_code","country_code":86,"phone_number":"15779784953","apple_open_id":"some text","bind_token":"some text","code":"some text","open_id":"some text","third_party_channel":"WeChat | QQ"}
 */
export interface SignInPhoneRequest {
  /** 指定手机登录的方式：`sms_code`为短信验证码；`onekey_aliyun`为阿里云SDK一键登录； */
  method_name: "sms_code" | "aliyun_sdk";

  /** 国家代码，中国为86 */
  country_code: number;

  /** 手机号 */
  phone_number: string;

  /** 苹果的OpenId */
  apple_open_id?: string;

  /** 绑定第三方登录的凭证 */
  bind_token?: string;

  /** 验证码，或一键登录的token */
  code: string;
  open_id?: string;

  /** WeChat | QQ */
  third_party_channel?: string;
}

/**
 * 登录成功的响应
 * @example {"token":"eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJlMTM3Y2E0OC0xYmFhLTQ3MjctODgxNC0xMmY1Y2NkYzM4ZDMiLCJpc3MiOiJodHRwOi8vZXhhbXBsZS5vcmciLCJhdWQiOiJodHRwOi8vZXhhbXBsZS5vcmciLCJzdWIiOiJhZG1pbiIsImlhdCI6MTYxNTQ3MzQ3NSwiZXhwIjoxNjE1NDc3MDc1LCJhdXRob3JpdGllcyI6WyJBRE1JTiIsIlVTRVIiXSwicmVmcmVzaENvdW50IjowLCJyZWZyZXNoTGltaXQiOjF9.GfF6EBzaaDRPFno_ij40ic9kmYTcJEYqJO7CCij3tiI","expired_date":"2020-03-01T00:00:00Z","country_code":86,"phone_number":"13300000000"}
 */
export type SignInPhoneResponse = (object & AuthTokenResponse) & { country_code: number; phone_number: string };

/**
 * 发送短信验证码的请求体
 * @example {"purpose":"SIGNIN","country_code":86,"phone_number":"13300000000","captcha":"21314"}
 */
export interface SendSmsCodeRequest {
  /** 发送短信的目的 */
  purpose: "SIGNIN";

  /**
   * 手机号的国家码，中国为86
   * @format int32
   */
  country_code?: number;

  /** 手机号码 */
  phone_number: string;

  /** 图形或者滑块验证码 */
  captcha: string;
}

/**
 * 往往是用来表示200成功的响应体
 * @example {"message":"ok"}
 */
export interface CommonResponse {
  message: string;
}

/**
 * 一个media文件的信息
 * @example {"size":10000,"filename":"xxxxxx.mp3"}
 */
export interface AnkiMediaFileInfo {
  /** @format int32 */
  size: number;
  filename: string;
}

/**
 * 卡册静态资源的映射信息
 * @example {"base_url":"https://media-qa.qmcdn.net/apkg/media/3322-3d8def/","mapping":{"chap.mp3":{"size":38478123,"filename":"f31098107b7915073f06573d2830ac3a83a3039d.mp3"},"left.png":{"size":102400,"filename":"573d2830ac3a83a3039df31098107b7915073f06.png"}}}
 */
export interface AnkiMediaMappingResponse {
  base_url: string;
  mapping: any;
}

/**
 * 阿里云人机验证的结果，同时附加业务请求结果
 * @example {"bizCode":100}
 */
export interface CaptchaActionResponse {
  /** @format int32 */
  bizCode?: number;
}

/**
 * 用户基本信息
 * @example {"nickname":"some text","image_url":"some text","phone_number_e164":"some text","locatoin":"some text","birthday":"2018-01-17","gender":"some text","is_bind_apple":true,"is_bind_wechat":true,"is_bind_qq":true,"is_edu_verified":true}
 */
export interface UserInfo {
  nickname?: string;
  image_url?: string;
  phone_number_e164?: string;
  locatoin?: string;

  /** @format date */
  birthday?: string;
  gender?: string;
  is_bind_apple?: boolean;
  is_bind_wechat?: boolean;
  is_bind_qq?: boolean;
  is_edu_verified?: boolean;
}

/**
 * @example {"url":"https://www.baidu.com/xxx"}
 */
export interface WeixinJsApiSignatureRequest {
  url?: string;
}

/**
 * @example {"appId":"wx729d86d68b8dfb6f","timestamp":1600000000,"nonceStr":"d9292e19e192912e","signature":"292d929e2d292d223"}
 */
export interface WeixinJsApiSignatureResponse {
  appId?: string;

  /** @format int64 */
  timestamp?: number;
  nonceStr?: string;
  signature?: string;
}

/**
 * @example {"status":"NOT_BOOKED","charge":"{xxxx.....xxxx}"}
 */
export interface Campaign202104StatusResponse {
  status: "NOT_BOOKED" | "BOOKED" | "ORDERED_NOT_PAID" | "ORDERED_PAID";

  /** 如果这个响应包含这个字段，则应该调用JSAPI或者Ping++SDK进行支付请求 */
  charge?: string;
}

/**
 * @example {"action":"PLACE_ORDER","openId":"xxxxx-yyy-zzz"}
 */
export interface Campaign202104OrderReqeust {
  action: "BOOK" | "PLACE_ORDER";
  openId?: string;
}

/**
 * 公众号网页授权登录的请求
 * @example {"code":"12312dsddinv","scope":"snsapi_base"}
 */
export interface WeixinMpCodeRequest {
  /** 回调得到的code */
  code?: string;

  /** 向微信请求授权的scope，详见 https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/Wechat_webpage_authorization.html#1 */
  scope: "snsapi_base" | "snsapi_userinfo";
}

/**
 * @example {"openId":"dsafsdfsdf"}
 */
export interface WeixinMpCodeResponse {
  openId?: string;
}

/**
 * 分为单位
 * @example {"ios_balance":100,"android_balance":200}
 */
export interface UserWalletResponse {
  /** @format int32 */
  ios_balance?: number;

  /** @format int32 */
  android_balance?: number;
}

/**
 * @example {"sku_id":"add_wallet_balance_ios_1"}
 */
export interface DepositWalletRequest {
  sku_id?: string;
}

/**
 * @example {"order_no":"xxx"}
 */
export interface DepositWalletResponse {
  order_no?: string;
}

/**
 * @example {"amount":1000,"description":"10R=10虾滑","delta":1000,"sku_id":"add_wallet_balance_ios_2"}
 */
export interface WalletSkuItem {
  /** @format int32 */
  amount?: number;
  description?: string;
  sku_id?: string;

  /** @format int32 */
  delta?: number;
}

/**
 * @example {"order_no":"1","transaction_receipt":"xx"}
 */
export interface CheckPaymentRequest {
  order_no?: string;
  transaction_receipt?: string;
}

/**
 * @example {"ios":{"version":"v1.0.3","force_version":"v1.0.2","title":"有重大更新","desc":["更新提示1","更新提示2"],"app_id":"","download_url":""},"android":{"version":"v1.0.3","force_version":"v1.0.2","title":"有重大更新","desc":"更新提示","channel":"","download_url":""}}
 */
export interface LatestAppVersionResponse {
  ios?: object;
  android?: object;
}

/**
 * @example {"content":[{"qm_title":"some text","qm_publisher":"some text","qm_cover":"some text","qm_description":"some text","library_book_id":30,"origin_book_author":"some text","origin_book_title":"some text","origin_book_cover":"some text","origin_book_description":"some text","category":"some text","expire_date":"some text","package_uuid":"some text","package_editor":"some text","package_proofreader":"some text","copyright":"some text","related_link":"some text","image_url_list":["some text","some text"]},{"qm_title":"some text","qm_publisher":"some text","qm_cover":"some text","qm_description":"some text","library_book_id":8,"origin_book_author":"some text","origin_book_title":"some text","origin_book_cover":"some text","origin_book_description":"some text","category":"some text","expire_date":"some text","package_uuid":"some text","package_editor":"some text","package_proofreader":"some text","copyright":"some text","related_link":"some text","image_url_list":["some text","some text"]}],"pageable":{"offset":38,"page_number":19,"page_size":51},"total_pages":62,"total_elements":95,"last":true,"size":62,"number":1,"number_of_elements":38,"first":true,"empty":true}
 */
export interface PageLibraryBook {
  content?: {
    qm_title?: string;
    qm_publisher?: string;
    qm_cover?: string;
    qm_description?: string;
    library_book_id?: number;
    origin_book_author?: string;
    origin_book_title?: string;
    origin_book_cover?: string;
    origin_book_description?: string;
    category?: string;
    expire_date?: string;
    package_uuid?: string;
    package_editor?: string;
    package_proofreader?: string;
    copyright?: string;
    related_link?: string;
    image_url_list?: string[];
    card_count?: number;
    borrower_count?: number;
    learned_card_count?: number;
    learned_card_count_today?: number;
    card_to_learn_per_day?: number;
    learn_status?: string;
  }[];
  pageable?: { offset?: number; page_number?: number; page_size?: number };

  /** @format int32 */
  total_pages?: number;

  /** @format int64 */
  total_elements?: number;
  last?: boolean;

  /** @format int32 */
  size?: number;

  /** @format int32 */
  number?: number;

  /** @format int32 */
  number_of_elements?: number;
  first?: boolean;
  empty?: boolean;
}

/**
 * @example {"quantity":1}
 */
export interface CreateOrderRequest {
  /** @format int32 */
  quantity?: number;
}

/**
 * @example {"order_id":55,"quantity":63,"amount":57}
 */
export interface CreateOrderResponse {
  /** @format int64 */
  order_id?: number;

  /** @format int32 */
  quantity?: number;

  /** @format int32 */
  amount?: number;
}

/**
 * @example {"sku":{"id":"library_book_lease_1","amount":1000},"lease":{"expire_date":"2021-04-16 03:20:42"}}
 */
export interface LibraryBookLeaseResponse {
  sku?: {
    id?: string;
    amount?: number;
    price?: {
      base_price?: number;
      edge_setting?: { day?: number; factor?: number; label_text?: string; is_show?: boolean }[];
    };
  };
  lease?: { expire_date?: string };
}

/**
 * @example {"order_id":7,"transaction_gateway":"PING_PP","pay_channel":"alipay|wx","coupon_id":13,"open_id":"ping++ 的openId"}
 */
export interface CreatePaymentRequest {
  /** @format int64 */
  order_id?: number;
  transaction_gateway?: "APPLE_PAY" | "PING_PP" | "IOS_WALLET" | "ALIPAY" | "WXPAY" | "ANDROID_WALLET";

  /** @format int64 */
  coupon_id?: number;
  pay_channel?: string;
  open_id?: string;
  success_callback_url?: string;
}

export interface SignInAppleRequest {
  identity_token?: string;
}

/**
 * @example {"openid":"xx"}
 */
export interface SignInQQRequest {
  openid?: string;
}

/**
 * @example {"id":123,"anki_model_id":1231412123,"field_values":["马克思主义理论体系的本质特征是：{{c1::科学性和革命性的统一}}","科学性和革命性的统一<div>唯物论与辩证法的统一</div><div>理论性与实践性的统一</div><div>阶级性和群众性的统一</div>","field33333"]}
 */
export interface AnkiNoteVo {
  /** @format int64 */
  anki_model_id?: number;
  field_values?: string[];

  /** @format int64 */
  id: number;
}

/**
 * @example {"id":1,"name":"单词","css":"body:{font-size: 30px;}","fields":["单词","音标","释义","发音","例句","例句翻译","拓展"]}
 */
export interface AnkiModelVo {
  /** @format int64 */
  id?: number;
  name?: string;
  css?: string;
  fields?: string[];
}

/**
 * @example {"id":1000000000,"note":{"id":123,"anki_model_id":1231412123,"field_values":["马克思主义理论体系的本质特征是：{{c1::科学性和革命性的统一}}","科学性和革命性的统一<div>唯物论与辩证法的统一</div><div>理论性与实践性的统一</div><div>阶级性和群众性的统一</div>","field33333"]},"ankiTemplateId":100000}
 */
export interface AnkiCardVo {
  /** @format int64 */
  id: number;
  note: AnkiNoteVo;

  /** @format int64 */
  ankiTemplateId: number;
}

/**
 * @example {"id":1000000,"name":"2 Spelling","parentAnkiDeckId":10000002,"isRoot":false,"cardIdList":[1,2,3,4,5]}
 */
export interface AnkiDeckVo {
  /** @format int64 */
  id?: number;
  name?: string;
  isRoot?: boolean;

  /** @format int64 */
  parentAnkiDeckId?: number;
  cardIdList?: number[];
}

/**
 * @example {"id":100000000000000,"name":"RECITE","card_front_format":"<a href=。。。>这里是正面模板</a>","card_back_format":"这里是背面的模板","anki_model_id":100000}
 */
export interface AnkiTemplateVo {
  /** @format int64 */
  id?: number;
  name?: string;
  card_front_format?: string;
  card_back_format?: string;

  /** @format int64 */
  anki_model_id: number;
}

/**
 * @example {"decks":[],"models":[],"templates":[]}
 */
export interface AnkiPackageVo {
  decks?: AnkiDeckVo[];
  models?: AnkiModelVo[];
  templates?: AnkiTemplateVo[];
}

/**
 * @example {"card_id_list":[1000,1001,1002]}
 */
export interface ApkgCardBulkGetRequest {
  card_id_list?: number[];
}

/**
 * @example {"cards":[]}
 */
export interface ApkgCardBulkGetResponse {
  cards?: AnkiCardVo[];
}

/**
 * @example {"deck_id":1619257434054}
 */
export interface NextCardListRequest {
  /** @format int64 */
  deck_id?: number;
}

/**
 * @example {"card_id_list":[1619257625104]}
 */
export interface NextCardListResponse {
  card_id_list?: number[];
}

/**
 * @example {"card_id":1619257434854,"card_rating_button_type":"EASY"}
 */
export interface RateCardRequest {
  /** @format int64 */
  card_id?: number;

  /** 4 valid values, AGAIN, HARD, GOOD, EASY. */
  card_rating_button_type?: string;
}

export interface InitDefaultUserConfigRequest {
  unused?: number;
}

/**
 * @example {"package_id":1619257434454,"card_rating_button_type":"DEFAULT"}
 */
export interface InitDefaultPackageAndDeckConfigRequest {
  /** @format int64 */
  package_id?: number;

  /** 3 valid values, DEFAULT, FREQUENT, CRAM. */
  study_mode?: string;
}

/**
 * @example {"package_id":1619257434054}
 */
export interface InitDefaultDeckAndCardDynamicScheduleRequest {
  /** @format int64 */
  package_id?: number;
}

/**
 * @example {"deck_id":1619257434854}
 */
export interface ResetSchedulerRequest {
  /** @format int64 */
  deck_id?: number;
}

/**
 * @example {"card_id":1619257434854}
 */
export interface RecordStudyAuditLogStartTimeRequest {
  /** @format int64 */
  card_id?: number;
}

/**
 * @example {"deck_id":1619257434854}
 */
export interface StudyCountTodayRequest {
  /** @format int64 */
  deck_id?: number;
}

/**
 * @example {"study_count_today":10}
 */
export interface StudyCountTodayResponse {
  /** @format int32 */
  study_count_today?: number;
}

/**
 * @example {"deck_id":1619257434854}
 */
export interface TodayStudyFinishedRelatedDataRequest {
  /** @format int64 */
  deck_id?: number;
}

/**
 * @example {"total_study_time_taken":128000,"debut_cards_count_checked_today":10,"review_phase_cards_count_checked_today":20,"total_study_days_count":80}
 */
export interface TodayStudyFinishedRelatedDataResponse {
  /** @format int32 */
  total_study_time_taken?: number;

  /** @format int32 */
  debut_cards_count_checked_today?: number;

  /** @format int32 */
  review_phase_cards_count_checked_today?: number;

  /** @format int32 */
  total_study_days_count?: number;
}

/**
 * @example {"deck_id":1619257434854,"study_days_count":10,"cards_count_limit_planned_per_day":80}
 */
export interface UpdateStudyPlanRequest {
  /** @format int64 */
  deck_id?: number;

  /** @format int32 */
  study_days_count?: number;

  /** @format int32 */
  cards_count_limit_planned_per_day?: number;
}

/**
 * @example {"deck_id":1619257434854,"study_days_extension_count":10}
 */
export interface ExtendStudyPlanRequest {
  /** @format int64 */
  deck_id?: number;

  /** @format int32 */
  study_days_extension_count?: number;
}

/**
 * @example {"url":"xx","name":"xx"}
 */
export interface UploadAnkiPackageRequest {
  url?: string;
  name?: string;
}

/**
 * @example {"device_fingerprint":"x","user_token":"x","event_id":"x","event_detail":"x"}
 */
export interface UserTraceSpan {
  device_fingerprint?: string;
  user_token?: string;
  event_id?: string;
  event_detail?: string;
}

/**
 * @example {"value":"v"}
 */
export interface UserPreference {
  value?: string;
}

/**
 * @example {"category_list":[{"title":"考研"},{"title":"MBA"}]}
 */
export interface GetLibraryCategoryResponse {
  category_list?: { title?: string }[];
}

/**
 * @example {"code":"071b7nFa1JVj0B0ty9Ga1Ia1Vp2b7nF0"}
 */
export interface SignInWeChatRequest {
  code?: string;
}

/**
 * @example {"open_id":"000967.32a6037ae44a4ddd9d4517f39ce6beb9.1231","apple_open_id":"000967.32a6037ae44a4ddd9d4517f39ce6beb9.1231","need_bind_phone":false,"token":"xxx","expire_date":"2020-03-01T00:00:00Z","bind_token":"xxx"}
 */
export interface SignInThirdPartyResponse {
  open_id?: string;
  apple_open_id?: string;
  need_bind_phone?: boolean;
  token?: string;

  /** @format date-time */
  expire_date?: string;
  bind_token?: string;
}

/**
 * @example {"coupons":[{"name":"新人券","description":"立减五十","end_date":"2021-04-10 23:26:52","discount_type":"absolute","discount_value":30,"discount_min":0,"discount_max":0,"price_min":0,"price_max":0,"is_consumed":true,"consumed_date":"2021-04-10 23:26:52"}]}
 */
export interface UserCouponListResponse {
  coupons?: {
    coupon_id?: number;
    name?: string;
    library_book_id?: number;
    category_id?: number;
    description?: string;
    end_date?: string;
    discount_type?: string;
    discount_value?: number;
    actual_discount_value?: number;
    discount_min?: number;
    discount_max?: number;
    price_min?: number;
    price_max?: number;
    is_consumed?: boolean;
    consumed_date?: string;
  }[];
}

/**
 * @example {"description":"some text","image_url_list":["some text","some text",null]}
 */
export interface LibraryBookReportRequest {
  description?: string;
  contact_info?: string;
  image_url_list?: string[];
}

/**
 * @example {"location":"中国","birthday":"1995-09-09","gender":"男","nickname":"xxx","image_url":"http:xxx.png"}
 */
export interface UpdateMyInfoRequest {
  location?: string;

  /** @format date */
  birthday?: string;
  gender?: string;
  nickname?: string;
  image_url?: string;
}

/**
 * @example {"lease_list":[{"order":{"order_id":12,"amount":60,"coupon":{"amount":30},"payment":{"gateway":"微信支付","amount":30}},"library_book":{"title":"刑法攻略","image_url":"image_url"}}]}
 */
export interface GetLibraryLeaseListResponse {
  lease_list?: {
    order?: {
      order_id?: number;
      created_date?: string;
      amount?: number;
      quantity?: number;
      order_status?: string;
      coupon?: { amount?: number };
      payment?: { gateway?: string; amount?: number };
    };
    library_book?: { title?: string; image_url?: string };
    resource_id?: number;
  }[];
}

export interface ForgettingCurveDotsDataRequest {
  unused?: number;
}

/**
 * @example {"ebbinghaus_curve_dots":[],"emo_algorithm_curve_dots":[],"user_actual_curve_dots":[]}
 */
export interface ForgettingCurveDotsDataResponse {
  ebbinghaus_curve_dots?: number[];
  memo_algorithm_curve_dots?: number[];
  user_actual_curve_dots?: number[];
}

/**
 * @example {"days_count":{"max_consecutive_study_days":1,"current_consecutive_study_days":1,"total_study_days":1},"daily_study_stat":[{"date":"12345678","today_start_time":"2021-05-18T21:00:00.000+00:00","total_study_time_taken":12,"again_button_click_times":1,"easy_button_click_times":2,"good_button_click_times":3,"hard_button_click_times":4}]}
 */
export interface StudyStat {
  days_count?: {
    max_consecutive_study_days?: number;
    current_consecutive_study_days?: number;
    total_study_days?: number;
  };
  daily_study_stat?: {
    date?: string;
    today_start_time?: string;
    total_study_time_taken?: number;
    again_button_click_times?: number;
    easy_button_click_times?: number;
    good_button_click_times?: number;
    hard_button_click_times?: number;
  }[];
}

/**
 * @example {"days":1}
 */
export interface GetDaysExtensionCountResponse {
  /** @format int32 */
  days?: number;
}

/**
 * @example {"deck_size":16,"learn_card_count_param":[{"days":15,"factor":4}]}
 */
export interface StudyPlanParam {
  /** @format int32 */
  deck_size?: number;
  learn_card_count_param?: { days?: number; factor?: number }[];
}

/**
 * @example {"library_book_list":[{"qm_title":"some text","qm_publisher":"some text","qm_cover":"some text","qm_description":"some text","library_book_id":30,"origin_book_author":"some text","origin_book_title":"some text","origin_book_cover":"some text","origin_book_description":"some text","category":"some text","expire_date":"some text","package_uuid":"some text","package_editor":"some text","package_proofreader":"some text","copyright":"some text","related_link":"some text"}]}
 */
export interface AppSearchResponse {
  library_book_list?: {
    qm_title?: string;
    qm_publisher?: string;
    qm_cover?: string;
    qm_description?: string;
    library_book_id?: number;
    origin_book_author?: string;
    origin_book_title?: string;
    origin_book_cover?: string;
    origin_book_description?: string;
    category?: string;
    expire_date?: string;
    package_uuid?: string;
    package_editor?: string;
    package_proofreader?: string;
    copyright?: string;
    related_link?: string;
  }[];
}

/**
 * @example {"title":"重复是学习之母。","author":"狄更斯"}
 */
export interface QuoteResponse {
  title?: string;
  author?: string;
}

/**
 * @example {"creator_gather_url":"some text","china_machine_table":"some text"}
 */
export interface AppInfoResponse {
  creator_gather_url?: string;
  china_machine_table?: string;
}

/**
 * @example {"qm_title":"some text","qm_publisher":"some text","qm_cover":"some text","qm_description":"some text","library_book_id":30,"origin_book_author":"some text","origin_book_title":"some text","origin_book_cover":"some text","origin_book_description":"some text","category":"some text","expire_date":"some text","package_uuid":"some text","package_editor":"some text","package_proofreader":"some text","copyright":"some text","related_link":"some text","image_url_list":["some text","some text"]}
 */
export interface GetLibraryBookInfo {
  qm_title?: string;
  qm_publisher?: string;
  qm_cover?: string;
  qm_description?: string;

  /** @format int64 */
  library_book_id?: number;
  origin_book_author?: string;
  origin_book_title?: string;
  origin_book_cover?: string;
  origin_book_description?: string;
  category?: string;

  /** @format date-time */
  expire_date?: string;
  package_uuid?: string;
  package_editor?: string;
  package_proofreader?: string;
  copyright?: string;
  related_link?: string;
  image_url_list?: string[];

  /** @format int32 */
  card_count?: number;

  /** @format int32 */
  borrower_count?: number;

  /** @format int32 */
  learned_card_count?: number;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";

function reviver(key, value) {
  if (value && value.isLosslessNumber) {
    if (Number.isSafeInteger(parseInt(value.value))) {
      return parseInt(value.value)
    }
    return value.value
  } else {
    return value
  }
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  private instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private eventDimHeaders?: {};

  constructor({ securityWorker, secure, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || "http://localhost:8080/api",
      transformResponse: [
        (data, headers) => {
          if (typeof data === "string") {
            try {
              data = parse(data, reviver)
            } catch (e) {
              /* Ignore */
            }
          }
          return data
        },
      ]
    });


    this.secure = secure;
    this.securityWorker = securityWorker;
    this.eventDimHeaders = {
      "X-QM-H5-Source": "wx",
      "X-QM-Device-FingerPrint": getFingerprint(),
      "X-QM-APP-Channel": "weixin_pub",
      "X-QM-ViewPage": getCurRoutePath()
    }
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
        ...(this.eventDimHeaders || {})
      },
    };
  }

  public request = async <T = any, E = any>({
    secure,
    path,
    type,
    query,
    format = "json",
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Qianmo API
 * @version 1.0.0
 * @baseUrl http://localhost:8080/api
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name SignInByPhone
     * @summary 手机号登录（短信、一键登录）
     * @request POST:/auth/v1/signin/phone
     * @secure
     */
    signInByPhone: (data: SignInPhoneRequest, params: RequestParams = {}) =>
      this.request<SignInPhoneResponse, ErrorResponse>({
        path: `/auth/v1/signin/phone`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SignInByApple
     * @summary 苹果登录
     * @request POST:/auth/v1/signin/apple
     * @secure
     */
    signInByApple: (data: SignInAppleRequest, params: RequestParams = {}) =>
      this.request<SignInThirdPartyResponse, any>({
        path: `/auth/v1/signin/apple`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UnbindAppleId
     * @summary 取消苹果登录的绑定
     * @request DELETE:/auth/v1/signin/apple
     * @secure
     */
    unbindAppleId: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/v1/signin/apple`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name SignInByWeChat
     * @summary 第三方微信登录
     * @request POST:/auth/v1/signin/wechat
     * @secure
     */
    signInByWeChat: (data: SignInWeChatRequest, params: RequestParams = {}) =>
      this.request<SignInThirdPartyResponse, any>({
        path: `/auth/v1/signin/wechat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UnbindWeChat
     * @summary 解除微信登录
     * @request DELETE:/auth/v1/signin/wechat
     * @secure
     */
    unbindWeChat: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/v1/signin/wechat`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name SignInByQq
     * @summary 通过QQ登录
     * @request POST:/auth/v1/signin/qq
     * @secure
     */
    signInByQq: (data: SignInQQRequest, params: RequestParams = {}) =>
      this.request<SignInThirdPartyResponse, any>({
        path: `/auth/v1/signin/qq`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UnbindQq
     * @summary 解除QQ绑定
     * @request DELETE:/auth/v1/signin/qq
     * @secure
     */
    unbindQq: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/v1/signin/qq`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name SignInSendSmsCode
     * @summary 发送短信，附带阿里云验证
     * @request POST:/auth/v1/signin/send_sms_code
     * @secure
     */
    signInSendSmsCode: (data: SendSmsCodeRequest, params: RequestParams = {}) =>
      this.request<CaptchaActionResponse, CaptchaActionResponse>({
        path: `/auth/v1/signin/send_sms_code`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthGetMyInfo
     * @summary 获得当前用户信息
     * @request GET:/auth/v1/my_info
     * @secure
     */
    authGetMyInfo: (params: RequestParams = {}) =>
      this.request<UserInfo, CommonResponse>({
        path: `/auth/v1/my_info`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateMyInfo
     * @summary 修改个人信息
     * @request PUT:/auth/v1/my_info
     * @secure
     */
    updateMyInfo: (data: UpdateMyInfoRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/v1/my_info`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  demo = {
    /**
     * No description
     *
     * @tags Demo
     * @name DemoTest
     * @request GET:/demo/test
     * @secure
     */
    demoTest: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/demo/test`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  apkg = {
    /**
     * No description
     *
     * @tags Apkg
     * @name ApkgGetPackageMediaMapping
     * @summary 获得一个资源包的所有媒体文件映射
     * @request GET:/apkg/v1/package/{package_uuid}/media_mapping
     * @secure
     */
    apkgGetPackageMediaMapping: (packageUuid: string, params: RequestParams = {}) =>
      this.request<AnkiMediaMappingResponse, any>({
        path: `/apkg/v1/package/${packageUuid}/media_mapping`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Apkg
     * @name ApkgGetPackageInfo
     * @summary 获得某个apkg的基本信息（模板、卡册等）
     * @request GET:/apkg/v1/package/{package_uuid}/info
     * @secure
     */
    apkgGetPackageInfo: (packageUuid: string, params: RequestParams = {}) =>
      this.request<AnkiPackageVo, any>({
        path: `/apkg/v1/package/${packageUuid}/info`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Apkg
     * @name ApkgBulkGetCards
     * @summary 批量获得cards
     * @request POST:/apkg/v1/package/{package_uuid}/cards/bulk
     * @secure
     */
    apkgBulkGetCards: (packageUuid: string, data: ApkgCardBulkGetRequest, params: RequestParams = {}) =>
      this.request<ApkgCardBulkGetResponse, any>({
        path: `/apkg/v1/package/${packageUuid}/cards/bulk`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Apkg
     * @name UploadAnkiPackage
     * @summary 上传anki卡包到数据库
     * @request POST:/apkg/v1/package
     * @secure
     */
    uploadAnkiPackage: (data: UploadAnkiPackageRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/apkg/v1/package`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  weixin = {
    /**
     * No description
     *
     * @tags Weixin
     * @name MpCreateJsApiSignature
     * @request POST:/weixin/v1/mp/create_js_api_signature
     * @secure
     */
    mpCreateJsApiSignature: (data: WeixinJsApiSignatureRequest, params: RequestParams = {}) =>
      this.request<WeixinJsApiSignatureResponse, any>({
        path: `/weixin/v1/mp/create_js_api_signature`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Weixin
     * @name MpCodeAuthorize
     * @summary 公众号授权，获得openId/UnionId或者其它信息
     * @request POST:/weixin/v1/mp/code_authorize
     * @secure
     */
    mpCodeAuthorize: (data: WeixinMpCodeRequest, params: RequestParams = {}) =>
      this.request<WeixinMpCodeResponse, any>({
        path: `/weixin/v1/mp/code_authorize`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Weixin
     * @name MpCallback
     * @summary 公众号回调页面
     * @request GET:/weixin/v1/mp/callback
     * @secure
     */
    mpCallback: (
      query?: { signature?: string; timestamp?: string; nonce?: string; echostr?: string; r?: string; code?: string },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/weixin/v1/mp/callback`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  campaign = {
    /**
     * No description
     *
     * @tags Campaign
     * @name Campaign202104GetStatus
     * @summary 获取预约、预购状态
     * @request GET:/campaign/v1/202104/item/{item_id}/preorder
     * @secure
     */
    campaign202104GetStatus: (itemId: string, params: RequestParams = {}) =>
      this.request<Campaign202104StatusResponse, any>({
        path: `/campaign/v1/202104/item/${itemId}/preorder`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign
     * @name Campaign202104Post
     * @summary 发起预约或者预购请求
     * @request POST:/campaign/v1/202104/item/{item_id}/preorder
     * @secure
     */
    campaign202104Post: (itemId: string, data: Campaign202104OrderReqeust, params: RequestParams = {}) =>
      this.request<Campaign202104StatusResponse, any>({
        path: `/campaign/v1/202104/item/${itemId}/preorder`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  payment = {
    /**
     * No description
     *
     * @tags Payment
     * @name PingPpCallback
     * @summary Ping++支付回调
     * @request POST:/payment/v1/pingpp/callback
     * @secure
     */
    pingPpCallback: (data: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payment/v1/pingpp/callback`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name ApplePayCallback
     * @summary 检查苹果支付结果，并且发放商品
     * @request POST:/payment/v1/apple/callback
     * @secure
     */
    applePayCallback: (data: CheckPaymentRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payment/v1/apple/callback`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateTransactionOrder
     * @summary 创建支付订单
     * @request POST:/payment/v1/orders/{order_id}
     * @secure
     */
    createTransactionOrder: (orderId: number, data: CreatePaymentRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payment/v1/orders/${orderId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags Wallet
     * @name GetMyWallet
     * @request GET:/user/v1/wallet
     * @secure
     */
    getMyWallet: (params: RequestParams = {}) =>
      this.request<UserWalletResponse, any>({
        path: `/user/v1/wallet`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wallet
     * @name DepositSkuList
     * @summary 获取可用的充值列表
     * @request GET:/user/v1/wallet/deposit/skus
     * @secure
     */
    depositSkuList: (params: RequestParams = {}) =>
      this.request<WalletSkuItem[], any>({
        path: `/user/v1/wallet/deposit/skus`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wallet
     * @name DepositWalletBalance
     * @summary 钱包充值
     * @request POST:/user/v1/wallet/deposit/buy
     * @secure
     */
    depositWalletBalance: (data: DepositWalletRequest, params: RequestParams = {}) =>
      this.request<DepositWalletResponse, any>({
        path: `/user/v1/wallet/deposit/buy`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetUserPreference
     * @summary 获取用户偏好设置
     * @request GET:/user/v1/preference/{key}
     * @secure
     */
    getUserPreference: (key: string, params: RequestParams = {}) =>
      this.request<UserPreference, any>({
        path: `/user/v1/preference/${key}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SetUserPreference
     * @summary 设置用户偏好
     * @request POST:/user/v1/preference/{key}
     * @secure
     */
    setUserPreference: (key: string, data: UserPreference, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/v1/preference/${key}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  app = {
    /**
     * No description
     *
     * @tags Versions
     * @name GetLatestVersion
     * @summary 获取最新版本信息
     * @request GET:/app/v1/versions/latest
     * @secure
     */
    getLatestVersion: (params: RequestParams = {}) =>
      this.request<LatestAppVersionResponse, any>({
        path: `/app/v1/versions/latest`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name Search
     * @summary APP 首页搜索
     * @request GET:/app/v1/search
     * @secure
     */
    search: (query?: { q?: string }, params: RequestParams = {}) =>
      this.request<AppSearchResponse, any>({
        path: `/app/v1/search`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAppInfo
     * @summary 获取APP信息
     * @request GET:/app/v1/info
     * @secure
     */
    getAppInfo: (params: RequestParams = {}) =>
      this.request<AppInfoResponse, any>({
        path: `/app/v1/info`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  books = {
    /**
     * No description
     *
     * @name FindLibraryBooks
     * @summary 分页获取图书馆
     * @request GET:/books/v1/library
     * @secure
     */
    findLibraryBooks: (
      query?: { page_number?: number; page_size?: number; category?: string },
      params: RequestParams = {},
    ) =>
      this.request<PageLibraryBook, any>({
        path: `/books/v1/library`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name FindMyPaidBooks
     * @summary 获取我购买的所有图书
     * @request GET:/books/v1/my
     * @secure
     */
    findMyPaidBooks: (query?: { page_number?: number; page_size?: number }, params: RequestParams = {}) =>
      this.request<PageLibraryBook, any>({
        path: `/books/v1/my`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetLibraryInfo
     * @summary 获取图书的详细信息
     * @request GET:/books/v1/library/info/{library_book_id}
     * @secure
     */
    getLibraryInfo: (libraryBookId: number, params: RequestParams = {}) =>
      this.request<GetLibraryBookInfo, any>({
        path: `/books/v1/library/info/${libraryBookId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetLibraryBookSku
     * @summary 获取当前图书的租约以及价格
     * @request GET:/books/v1/library/lease/{library_book_id}
     * @secure
     */
    getLibraryBookSku: (libraryBookId: number, params: RequestParams = {}) =>
      this.request<LibraryBookLeaseResponse, any>({
        path: `/books/v1/library/lease/${libraryBookId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MakeLibraryBookLease
     * @summary 租借图书
     * @request POST:/books/v1/library/lease/{library_book_id}
     * @secure
     */
    makeLibraryBookLease: (libraryBookId: number, data: CreateOrderRequest, params: RequestParams = {}) =>
      this.request<CreateOrderResponse, any>({
        path: `/books/v1/library/lease/${libraryBookId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @name MakeLibraryBookLeaseWithAiPlan
     * @summary 租借图书（带AI计划）
     * @request POST:/books/v1/library/lease/{library_book_id}/withplan
     * @secure
     */
    makeLibraryBookLeaseWithAiPlan: (libraryBookId: number, data: CreateOrderRequest, params: RequestParams = {}) =>
        this.request<CreateOrderResponse, any>({
          path: `/books/v1/library/lease/${libraryBookId}/withplan`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
    /**
     * No description
     *
     * @name ReportBook
     * @summary 举报图书
     * @request POST:/books/v1/library/report/{library_book_id}
     * @secure
     */
    reportBook: (libraryBookId: number, data: LibraryBookReportRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/books/v1/library/report/${libraryBookId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description 默认返回二十个，返回最旧的 resourceId ，可以继续前翻
     *
     * @name GetAllMyLease
     * @summary 获取我的所有租借订单
     * @request GET:/books/v1/library/lease
     * @secure
     */
    getAllMyLease: (query?: { resourceId?: number }, params: RequestParams = {}) =>
      this.request<GetLibraryLeaseListResponse, any>({
        path: `/books/v1/library/lease`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  scheduler = {
    /**
     * @description Return next card id list for user from deck, scheduled by CardScheduler. Currently, the size of card id list could be either 1 (if there EXISTS card that could be learned/reviewed today) or 0 (if NO cards could be learned/reviewed today).
     *
     * @tags scheduler
     * @name GetNextCardList
     * @request POST:/scheduler/v1/next_card_list
     * @secure
     */
    getNextCardList: (data: NextCardListRequest, params: RequestParams = {}) =>
      this.request<NextCardListResponse, any>({
        path: `/scheduler/v1/next_card_list`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Called when user click card rating buttons. Will affect future card schedule.
     *
     * @tags scheduler
     * @name RateCard
     * @request POST:/scheduler/v1/rate_card
     * @secure
     */
    rateCard: (data: RateCardRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/scheduler/v1/rate_card`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Init default user-level config for card schedule, should be called when user registered.
     *
     * @tags scheduler
     * @name InitDefaultUserConfig
     * @request POST:/scheduler/v1/init_default_user_config
     * @secure
     */
    initDefaultUserConfig: (data: InitDefaultUserConfigRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/scheduler/v1/init_default_user_config`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Init default package-level & deck-level config for card schedule of specific user, should be called when user own the package.
     *
     * @tags scheduler
     * @name InitDefaultPackageAndDeckConfig
     * @request POST:/scheduler/v1/init_default_package_and_deck_config
     * @secure
     */
    initDefaultPackageAndDeckConfig: (data: InitDefaultPackageAndDeckConfigRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/scheduler/v1/init_default_package_and_deck_config`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Init default deck-level & card-level dynamic schedule data of specific user, should be called before user's first time learning of package.
     *
     * @tags scheduler
     * @name InitDefaultDeckAndCardDynamicSchedule
     * @request POST:/scheduler/v1/init_default_deck_and_card_dynamic_schedule
     * @secure
     */
    initDefaultDeckAndCardDynamicSchedule: (
      data: InitDefaultDeckAndCardDynamicScheduleRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/scheduler/v1/init_default_deck_and_card_dynamic_schedule`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Reset user, package & deck-level data for card schedule.
     *
     * @tags scheduler
     * @name ResetScheduler
     * @request POST:/scheduler/v1/reset_scheduler
     * @secure
     */
    resetScheduler: (data: ResetSchedulerRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/scheduler/v1/reset_scheduler`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Record study audit log start time.
     *
     * @tags scheduler
     * @name RecordStudyAuditLogStartTime
     * @request POST:/scheduler/v1/record_study_audit_log_start_time
     * @secure
     */
    recordStudyAuditLogStartTime: (data: RecordStudyAuditLogStartTimeRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/scheduler/v1/record_study_audit_log_start_time`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get study count today.
     *
     * @tags scheduler
     * @name GetStudyCountToday
     * @request POST:/scheduler/v1/get_study_count_today
     * @secure
     */
    getStudyCountToday: (data: StudyCountTodayRequest, params: RequestParams = {}) =>
      this.request<StudyCountTodayResponse, any>({
        path: `/scheduler/v1/get_study_count_today`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags scheduler
     * @name GetTodayStudyFinishedRelatedData
     * @summary 今日学习完成相关数据
     * @request POST:/scheduler/v1/get_today_study_finished_related_data
     * @secure
     */
    getTodayStudyFinishedRelatedData: (data: TodayStudyFinishedRelatedDataRequest, params: RequestParams = {}) =>
      this.request<TodayStudyFinishedRelatedDataResponse, any>({
        path: `/scheduler/v1/get_today_study_finished_related_data`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  studystat = {
    /**
     * No description
     *
     * @name GetForgettingCurveDotsData
     * @summary 遗忘曲线数据点
     * @request POST:/studystat/v1/get_forgetting_curve_dots_data
     * @secure
     */
    getForgettingCurveDotsData: (data: ForgettingCurveDotsDataRequest, params: RequestParams = {}) =>
      this.request<ForgettingCurveDotsDataResponse, any>({
        path: `/studystat/v1/get_forgetting_curve_dots_data`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetMyStudyStat
     * @summary 获取我的学习计划统计数据
     * @request GET:/studystat/v1/my
     * @secure
     */
    getMyStudyStat: (query: { start_date: number; end_date: number }, params: RequestParams = {}) =>
      this.request<StudyStat, any>({
        path: `/studystat/v1/my`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  log = {
    /**
     * No description
     *
     * @name PostUserSpan
     * @summary 追踪用户行为数据
     * @request POST:/log/v1/spans
     * @secure
     */
    postUserSpan: (data: UserTraceSpan, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/log/v1/spans`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  library = {
    /**
     * No description
     *
     * @name GetLibraryCategoryList
     * @summary 获取图书馆分类列表
     * @request GET:/library/v1/category
     * @secure
     */
    getLibraryCategoryList: (params: RequestParams = {}) =>
      this.request<GetLibraryCategoryResponse, any>({
        path: `/library/v1/category`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  coupon = {
    /**
     * No description
     *
     * @name GetMyCouponList
     * @summary 获取我的所有优惠券
     * @request GET:/coupon/v1/my
     * @secure
     */
    getMyCouponList: (query?: { library_book_id?: number; order_id?: number }, params: RequestParams = {}) =>
      this.request<UserCouponListResponse, any>({
        path: `/coupon/v1/my`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  quote = {
    /**
     * No description
     *
     * @name RandomQuote
     * @summary 随机获得一句警世名言
     * @request GET:/quote/v1/random
     * @secure
     */
    randomQuote: (params: RequestParams = {}) =>
      this.request<QuoteResponse, any>({
        path: `/quote/v1/random`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  studyplan = {
    /**
     * No description
     *
     * @name GetDeckStudyPlan
     * @summary 获取学习计划
     * @request GET:/studyplan/v1/decks/{deckId}
     * @secure
     */
    getDeckStudyPlan: (deckId: number, params: RequestParams = {}) =>
      this.request<StudyPlanParam, any>({
        path: `/studyplan/v1/decks/${deckId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetExtendDaysCount
     * @summary 获取续期天数
     * @request GET:/studyplan/v1/decks/{deckId}/extend
     * @secure
     */
    getExtendDaysCount: (deckId: number, params: RequestParams = {}) =>
      this.request<GetDaysExtensionCountResponse, any>({
        path: `/studyplan/v1/decks/${deckId}/extend`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateStudyPlan
     * @summary 更新学习计划
     * @request POST:/studyplan/v1/update_study_plan
     * @secure
     */
    updateStudyPlan: (data: UpdateStudyPlanRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/studyplan/v1/update_study_plan`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ExtendStudyPlan
     * @summary 延期学习计划（但是不更新每天学习卡数）
     * @request POST:/studyplan/v1/extend_study_plan
     * @secure
     */
    extendStudyPlan: (data: ExtendStudyPlanRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/studyplan/v1/extend_study_plan`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  logging = {
    /**
     * No description
     *
     * @name LoggingAddLog
     * @summary 添加一条日志（会打印到ES中）
     * @request POST:/logging/v1/log
     * @secure
     */
    loggingAddLog: (data: { level?: "info" | "warning" | "error"; message?: string }, params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/logging/v1/log`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
