
import {Api, WeixinJsApiSignatureResponse} from "@/backendClient/Api";
import wx from "weixin-js-sdk"
import {AxiosResponse} from "axios";

export

class WeixinMpService {
  private client = new Api({baseURL: `${process.env.VUE_APP_BACKEND_URL}/api`})

  public appId = "wx729d86d68b8dfb6f"

  private signaturePromise : Promise<AxiosResponse<WeixinJsApiSignatureResponse>> | undefined;
  private getJsApiSignature() {
    if (this.signaturePromise == undefined) {
      this.signaturePromise =  this.client.weixin.mpCreateJsApiSignature({url: window.location.href});
    }
    return this.signaturePromise;
  }

  public async doConfig() : Promise<void> {
    const response = await this.getJsApiSignature()
    const sig = response.data;
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: this.appId, // 必填，公众号的唯一标识
      timestamp: sig.timestamp, // 必填，生成签名的时间戳
      nonceStr: sig.nonceStr, // 必填，生成签名的随机串
      signature: sig.signature,// 必填，签名
      jsApiList: [
        'checkJsApi',
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareWeibo',
        'onMenuShareQZone',
        'hideMenuItems',
        'showMenuItems',
        'hideAllNonBaseMenuItem',
        'showAllNonBaseMenuItem',
        'translateVoice',
        'startRecord',
        'stopRecord',
        'onVoiceRecordEnd',
        'playVoice',
        'onVoicePlayEnd',
        'pauseVoice',
        'stopVoice',
        'uploadVoice',
        'downloadVoice',
        'chooseImage',
        'previewImage',
        'uploadImage',
        'downloadImage',
        'getNetworkType',
        'openLocation',
        'getLocation',
        'hideOptionMenu',
        'showOptionMenu',
        'closeWindow',
        'scanQRCode',
        'chooseWXPay',
        'openProductSpecificView',
        'addCard',
        'chooseCard',
        'openCard'
      ] // 必填，需要使用的JS接口列表
    });

  }

  get openId(): string | null {
    return localStorage.getItem("wxOpenId");
  }

  set openId(value: string | null) {
    if (value === null) {
      localStorage.removeItem("wxOpenId")
    } else {
      localStorage.setItem("wxOpenId", value);
    }
  }
}

export default new WeixinMpService();
