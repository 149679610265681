






























import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";

@Component
export default class ItemDetail extends Vue {
  @Prop() itemId ?: string
  @Prop() spm ?: number

  getImageUrl(): string {
    let idx = this.itemId?.substr(this.itemId?.length - 3)
    let imageUrl = "";

    // TODO(fuchun): fix require issue
    if (idx) {
      if (this.spm == 0 || this.spm == 1) {
        imageUrl = require('../../assets/preorder/' + idx + '.png');
      }
      if (this.spm == 2) {
        imageUrl = require('../../assets/preorder/template-2/' + idx + '.jpg');
      }
    }

    return imageUrl.toString()
  }

}
