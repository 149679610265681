<template>
  <div style="background-color: white;position:absolute;width:100%;height:100%">
    <div>
      <div style="margin-top: 126px;text-align: center;">
        <img style="height: 84px; width: 84px;"
             src="https://qm-cdn-media-qa.oss-cn-hangzhou.aliyuncs.com/backUser/config/2270ce28dc6b4de9bdd542729dc2b21b">
      </div>

      <div style="text-align: center;font-size: 16px;line-height: 22px;">
        滑记
      </div>
      <div style="text-align: center;"
           @click="appDownload()">
        <div
            style="margin: 110px 70px; height: 44px; line-height: 44px; color: white;font-size: 15px; background-color: #7AC3A7;">
          <span v-if="!isIOS">Android 下载</span>
          <span v-if="isIOS">前往App store</span>
        </div>
      </div>
    </div>

    <div id="overlay" class="display-none">
      <div class="guide-overlay">
        <div class="pointer"></div>
        <div class="guide-info">
          请在浏览器打开~
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Vue} from "vue-property-decorator";

export default class AppDownload extends Vue {
  isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

  appDownload() {
    if (this.isIOS) {
      window.location.href = "https://apps.apple.com/cn/app/id1563391530"
    } else {
      if (this.isWeixin()) {
        this.guideUserOpenInSystemBrowser()
      } else {
        window.location.href = "https://cdn.huajiapp.com/android_apk/latest-android.apk"
      }
    }
  }

  guideUserOpenInSystemBrowser() { //判断是否是微信
    document.querySelector("#overlay").classList.remove("display-none");
  }

  isWeixin() { //判断是否是微信
    const ua = navigator.userAgent.toLowerCase();
    return ua.match(/MicroMessenger/i) == "micromessenger";
  }
}

</script>

<style scoped>
.guide-overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 300;
}

.display-none {
  display: none;
}

.pointer {
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom: 16px solid white;
  right: 11px;
  top: 0px;
  position: absolute;
}

.guide-info {
  top: 16px;
  right: 11px;
  font-size: 13px;
  position: absolute;
  background: white;
  border-radius: 3px;
  padding: 12px 24px;
}
</style>