import {UserTraceSpan} from "@/backendClient/Api";
import {getFingerprint, getJWT, getSpm, SPM, getSku, SKU} from "@/utils/localStorage";

export const CLICK_BOOK_NOW_BUTTON_PREORDER = "campaign202104-click-book-now-button"
export const VIEW_PAGE_PREORDER = "campaign202104-view-page"
export const FINISH_LOGIN_PREORDER = "campaign202104-finish-login"
export const CLICK_PAY_BUTTON_PREORDER = "campaign202104-click-pay-button"
export const FINISH_PAY_PREORDER = "campaign202104-finish-pay"

export const VIEW_PAGE = "1.view-page"
export const FINISH_LOGIN = "2.finish-login"
export const CLICK_BORROW_BUTTON = "3.click-borrow-button"
export const CLICK_BOOK_NOW_BUTTON = "4.click-book-now-button"
export const CLICK_PAY_BUTTON = "5.click-pay-button"
export const FINISH_PAY = "6.finish-pay"


// eslint-disable-next-line
export function logUserAction(client, eventId: string, eventDetail: any): void {
  eventDetail[SPM] = getSpm()
  eventDetail[SKU] = getSku()
  const trace: UserTraceSpan = {
    "device_fingerprint": getFingerprint(),
    "user_token": getJWT(),
    "event_id": eventId,
    "event_detail": JSON.stringify(eventDetail)
  };
  client.log.postUserSpan(trace).then();
  return
}
