export const FINGERPRINT = "fingerprint";
export const CUR_ROUTE_PATH = "cur_route_path"
export const SPM = "spm";
export const JWT = "jwt";
export const SKU = "sku";

export function getSpm(): number {
  return parseInt(localStorage[SPM]);
}

export function getSku(): string {
  return localStorage[SKU];
}

export function getFingerprint(): string {
  return localStorage[FINGERPRINT];
}

export function getJWT(): string {
  return localStorage[JWT];
}

export function getCurRoutePath(): string {
  return localStorage[CUR_ROUTE_PATH];
}