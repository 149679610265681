













import {Component, Vue} from "vue-property-decorator";

@Component
export default class OnlyWechatAllowed extends Vue {
  private debug = ""
  mounted() : void {
    if (process.env.NODE_ENV != "production") {
      this.debug = navigator.userAgent
    }
  }
}

